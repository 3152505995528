﻿// https://bulma.io/documentation/form/input/#variables

$input-focus-border-color: $yellow;
$input-focus-box-shadow-color: hsl(0, 0%, 96%);

.input[readonly] {
	background-color: transparent;
	border: 1px solid rgba(0, 0, 0, 0.2);
	cursor: default;
	pointer-events: none;

	&::placeholder {
		color: $black;
	}
}

.input[type="number"] {
	padding-right: 0;
}
