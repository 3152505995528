// Custom styling for react-calendar dependency

.react-calendar-custom {
	.react-calendar {
		&__navigation {
			button {
				padding: 8px;
				border: none;
				background-color: $warning;
				border-radius: 0;
				display: flex;
				justify-content: center;
				align-items: center;

				&[disabled] {
					background-color: $grey-light;
				}
			}
		}

		&__month-view {
			&__weekdays__weekday {
				text-align: center;

				abbr {
					text-decoration: none;
					font-weight: bold;
				}
			}

			&__days__day {
				&--neighboringMonth {
					background-color: $warning-light;
				}

				&[disabled] {
					background-color: $grey-light;
				}
			}
		}

		&__tile {
			&--active {
				background-color: $warning;
			}

			&--now {
				border: 1px solid $warning;
			}
		}
	}
}
