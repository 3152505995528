.textarea[readonly] {
	background-color: transparent;
	border: 1px solid rgba(0, 0, 0, 0.2);
	cursor: default;
	pointer-events: none;

	&::placeholder {
		color: $black;
	}
}
