.icon.icon {
	// monkeypatch broken svg icons on safari
	display: inline-block;
	align-items: initial;
	justify-content: initial;
}

.icon {
	vertical-align: text-top;
}
