﻿// https://bulma.io/documentation/customize/variables/
// Note that multiple bulma default values are declared here
// just so that they can be used with theme modifications that are declared before the actual bulma is imported

$yellow: #feab00;
$red: hsl(348, 86%, 61%);
$green: hsl(153, 53%, 53%);
$cyan: hsl(207, 61%, 53%);
$text: hsl(0, 0%, 29%);
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 4%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-darker: hsl(0, 0%, 21%);

$border: $grey-lighter;

$radius: none;
$radius-small: none;
$radius-large: none;

$input-shadow: inset 0 1px 2px rgba($black, 0.1);
