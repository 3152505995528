.dropdown {
	.dropdown-trigger:after {
		content: "";
		position: fixed;
		opacity: 0;
		transition: opacity 0.3s;
		z-index: 1;
		cursor: default;
	}

	&.is-active .dropdown-trigger:after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.05);
		opacity: 1;
	}

	.dropdown-item:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	&.is-fullwidth {
		display: flex;

		.dropdown-trigger button * {
			justify-content: left;
			text-align: left;
		}

		* {
			width: 100%;
		}
	}
}
