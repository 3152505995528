.modal.modal {
	display: block;
	opacity: 0;
	transform: translateY(-100%);
	transition: opacity 0.5s, transform 0.2s;
	&.is-active {
		opacity: 1;
		transform: translateY(0);
	}

	.custom-overflow-visible {
		overflow: visible;
	}
}
